<template>
  <productosList />
</template>

<script>

const productosList = () => import('@/layouts/components/Productos/ProductosList.vue')

export default {
  components: {
    productosList,
  },
}
</script>

<style>

</style>
